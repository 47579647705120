import type { OrgAIModelParamsDetail } from '@aisekisan/anya-api'
import { ApiError, parseServerError, useOrgAIParamsDelete } from '@aisekisan/anya-api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
} from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { Delete20Regular } from '@fluentui/react-icons'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

export function OrgAIParamsDelete(props: {
  aiModelParams: OrgAIModelParamsDetail
}): ReactElement {
  const { aiModelParams } = props

  const t = useT()

  const [open, setOpen] = useState(false)

  const deleteAIParams = useOrgAIParamsDelete(aiModelParams.orgID)

  const close = () => setOpen(false)

  const handleDelete = () => {
    deleteAIParams.mutate(aiModelParams.id, { onSuccess: close })
  }

  const getErrorMessage = () => {
    if (deleteAIParams.error === null)
      return ''
    if (deleteAIParams.error instanceof ApiError) {
      switch (deleteAIParams.error.status) {
        case 404:
          return t('ai-param.error-404.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(_, data) => setOpen(data.open)}
      modalType="alert"
    >
      <DialogTrigger disableButtonEnhancement>
        <Button icon={<Delete20Regular />}>
          <T id="org.ai-param.delete.action" />
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            <T id="org.ai-param.delete.title" />
          </DialogTitle>
          <DialogContent>
            <OverlaySpinner visible={deleteAIParams.isPending} appearance="primary" />
            {deleteAIParams.error
              ? (
                  <div>
                    <ErrorAlert
                      title={t(`org.ai-param.delete.error`)}
                      help={getErrorMessage()}
                      detail={parseServerError(deleteAIParams.error)}
                      onClose={() => {
                        deleteAIParams.reset()
                        close()
                      }}
                    />
                  </div>
                )
              : null}
            <T id="org.ai-param.delete.hint" />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">
                <T id="org.ai-param.delete.cancel" />
              </Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={handleDelete}>
              <T id="org.ai-param.delete.submit" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
