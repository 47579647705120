import { Button, Field, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, tokens } from '@aisekisan/bond'
import { Copy20Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { RefrigerantPipeShape } from '../../../type'
import type { DataShapes } from '../type'
import { useT } from '../../../libs/intl/useT'
import { T } from '../../../libs/intl/t'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: tokens.spacingHorizontalXS,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  field: {
    width: '120px',
  },
})

interface Props {
  refrigerantPipes: RefrigerantPipeShape[]
  onUpdate: (value: DataShapes) => void
}

export function RefrigerantPipeForm(props: Props): ReactElement {
  const { refrigerantPipes, onUpdate } = props

  const t = useT()
  const styles = useStyles()

  const onUpdateProp = (
    value: string,
    key: keyof RefrigerantPipeShape,
    refrigerantPipe: RefrigerantPipeShape,
  ) => {
    refrigerantPipe[key] = Number(value)
    onUpdate(refrigerantPipes)
  }

  const onRemoveProp = (index: number) => {
    refrigerantPipes.splice(index, 1)
    onUpdate(refrigerantPipes)
  }

  const onDuplicateProp = (refrigerantPipe: RefrigerantPipeShape) => {
    refrigerantPipes.push(refrigerantPipe)
    onUpdate(refrigerantPipes)
  }

  return (
    <div className={styles.wrapper}>
      {refrigerantPipes.map((refrigerantPipe, index) => (
        <div className={styles.item} key={index}>
          <Field label={t('preset-property.shape.refrigerant-pipe.liquid')}>
            <Input
              type="number"
              value={
                refrigerantPipe.liquidPipeDiameter
                  ? refrigerantPipe.liquidPipeDiameter.toString()
                  : ''
              }
              onChange={(event) => {
                onUpdateProp(
                  event.target.value,
                  'liquidPipeDiameter',
                  refrigerantPipe,
                )
              }}
              className={styles.field}
              appearance="filled-darker"
            />
          </Field>
          <Field label={t('preset-property.shape.refrigerant-pipe.suction')}>
            <Input
              type="number"
              value={
                refrigerantPipe.suctionPipeDiameter
                  ? refrigerantPipe.suctionPipeDiameter.toString()
                  : ''
              }
              onChange={(event) => {
                onUpdateProp(
                  event.target.value,
                  'suctionPipeDiameter',
                  refrigerantPipe,
                )
              }}
              appearance="filled-darker"
              className={styles.field}
            />
          </Field>
          <div>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<MoreHorizontal20Regular />} />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={<Delete20Regular />}
                    onClick={() => onRemoveProp(index)}
                  >
                    <T id="preset-property.shape.delete" />
                  </MenuItem>
                  <MenuItem
                    icon={<Copy20Regular />}
                    onClick={() => onDuplicateProp(refrigerantPipe)}
                  >
                    <T id="preset-property.shape.duplicate" />
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        </div>
      ))}
    </div>
  )
}
