import { Tab, TabList, makeStyles, tokens, useIntl } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { useAIModelList, useConstructionAIParamsList } from '@aisekisan/anya-api'
import { ConstructionAIParamsEdit } from '../edit'
import { ConstructionAIParamsDelete } from '../delete'
import { ConstructionAIParamListEquipment } from './equipment'
import { EmptyContent } from '@/components/empty-content'

const useStyles = makeStyles({
  panel: {
    padding: `${tokens.spacingHorizontalS} 0`,
  },
  tabList: {
    width: '100%',
    overflowX: 'auto',
  },
  actions: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalS,
    paddingTop: tokens.spacingHorizontalS,
  },
})

export function ConstructionAIParamListAIModel(
  props: {
    constructionID: string
    selectedModel?: number
    setSelectedModel: (model: number) => void
  },
): ReactElement {
  const { constructionID, selectedModel, setSelectedModel } = props

  const { lang } = useIntl()
  const styles = useStyles()

  const list = useConstructionAIParamsList(constructionID).data ?? []
  const models = useAIModelList().data ?? []

  if (list.length === 0)
    return <EmptyContent />

  return (
    <div>
      <TabList
        className={styles.tabList}
        selectedValue={selectedModel ?? list[0].aiModelID}
        onTabSelect={(_, data) => setSelectedModel(data.value as number)}
      >
        {list.map(({ aiModelID }) => {
          const model = models.find((model) => {
            return model.ai_model_id === aiModelID && model.locale === lang
          })
          return (
            <Tab key={aiModelID} value={aiModelID}>
              {model?.localed}
            </Tab>
          )
        })}
      </TabList>
      <div className={styles.panel}>
        {list.filter(params => params.params.rules.length > 0).map((params) => {
          if (!selectedModel && params.aiModelID !== list[0].aiModelID)
            return null
          if (selectedModel && params.aiModelID !== selectedModel)
            return null
          return (
            <ConstructionAIParamListEquipment
              key={params.id + params.params.rules.length} // Unmount component when add/delete rules
              aiModelParams={params}
              actions={(
                <div className={styles.actions}>
                  <ConstructionAIParamsEdit aiModelParams={params} />
                  <ConstructionAIParamsDelete
                    list={list}
                    aiModelParams={params}
                    updateSelectedModel={setSelectedModel}
                  />
                </div>
              )}
            />
          )
        })}
      </div>
    </div>
  )
}
