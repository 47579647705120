import { Field, Input, Select, Subtitle2, makeStyles, tokens, useIntl } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { LocaledEquipmentClass } from '@aisekisan/anya-api'
import type { ConstructionAIParamsFormBody } from './type'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: tokens.spacingVerticalM,
  },
  field: {
    flex: 1,
  },
})

export function ConstructionAIParamsFormHydrantRule(props: {
  form: UseFormReturn<ConstructionAIParamsFormBody>
  equipmentClasses: LocaledEquipmentClass[]
}): ReactElement {
  const { form, equipmentClasses } = props
  const { errors } = form.formState

  const { lang } = useIntl()
  const styles = useStyles()

  return (
    <div>
      <Subtitle2>
        <T id="construction.ai-param.hydrant-rule.title" />
      </Subtitle2>
      <div className={styles.container}>
        <Field
          label={<T id="construction.ai-param.hydrant-rule.equipment" />}
          className={styles.field}
          validationState={errors.hydrantRule?.equipmentClass ? 'error' : 'none'}
          validationMessage={errors.hydrantRule?.equipmentClass?.message}
        >
          <Select
            appearance="filled-darker"
            {...form.register('hydrantRule.equipmentClass')}
          >
            {equipmentClasses
              .filter(equip => equip.locale === lang)
              .map(({ equipmentClass, localed }) => (
                <option key={equipmentClass} value={equipmentClass}>
                  {localed}
                </option>
              ))}
          </Select>
        </Field>
        <Field
          label={<T id="construction.ai-param.hydrant-rule.exchange-value" />}
          className={styles.field}
          validationState={errors.hydrantRule?.exchangeValue ? 'error' : 'none'}
          validationMessage={errors.hydrantRule?.exchangeValue?.message}
        >
          <Input
            type="number"
            appearance="filled-darker"
            defaultValue={form.getValues('hydrantRule.exchangeValue')}
            {...form.register('hydrantRule.exchangeValue')}
          />
        </Field>
      </div>
    </div>
  )
}
