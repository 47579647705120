import { Body1, Panel, makeStyles, tokens } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { Collections20Regular } from '@fluentui/react-icons'
import { OrgAIParamsCopy } from '../copy'
import { OrgAIParamListAIModel } from './ai-model'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  header: {
    marginBottom: 0,
  },
})

export function OrgAIParamList(props: { orgID: string }): ReactElement {
  const { orgID } = props
  const styles = useStyles()
  return (
    <Panel
      title={<T id="org.ai-param.list.title" />}
      icon={<Collections20Regular />}
      actions={<OrgAIParamsCopy orgID={orgID} />}
      headerClassName={styles.header}
    >
      <OrgAIParamListAIModel orgID={orgID} />
      <Body1 style={{ color: tokens.colorNeutralForeground3 }}>
        <T id="org.ai-param.guideline" />
        <ul style={{ margin: 0 }}>
          <li>
            <T id="org.ai-param.guideline.1" />
          </li>
          <li>
            <T id="org.ai-param.guideline.2" />
          </li>
        </ul>
      </Body1>
    </Panel>
  )
}
