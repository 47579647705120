import { type ReactElement, useState } from 'react'
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import {
  CollectionsAdd20Regular,
  Dismiss24Regular,
} from '@fluentui/react-icons'
import {
  ApiError,
  parseServerError,
  useConstructionAIParamsCreate,
} from '@aisekisan/anya-api'
import {
  type ConstructionAIParamsFormBody,
  toAIModelParamsServer,
} from './form/type'
import { ConstructionAIParamsForm } from './form/form'
import { ConstructionAIParamsCopy } from './copy'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

const DEFAULT_VALUES: ConstructionAIParamsFormBody = {
  aiModelID: '',
  hydrantRule: { equipmentClass: '', exchangeValue: '' },
  rules: [{
    id: crypto.randomUUID(),
    equipmentClass: '',
    formulas: [{
      id: crypto.randomUUID(),
      from: '',
      to: '',
      diameter: '',
    }],
  }],
}

const useStyles = makeStyles({
  content: {
    width: '100%',
    maxHeight: '50vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
  center: {
    textAlign: 'center',
  },
})

type CreateMode = 'select' | 'manual' | 'copy'

export function ConstructionAIParamsCreate(props: {
  orgID: string
  constructionID: string
  updateSelectedModel: (aiModelID: number) => void
}): ReactElement {
  const { orgID, constructionID, updateSelectedModel } = props

  const t = useT()

  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState<CreateMode>('select')

  const create = useConstructionAIParamsCreate(constructionID)
  const createAIParams = (values: ConstructionAIParamsFormBody) => {
    const { aiModelID, params } = toAIModelParamsServer(values)
    create.mutate(
      { aiModelID, params },
      {
        onSuccess: () => {
          setOpen(false)
          updateSelectedModel(aiModelID)
        },
      },
    )
  }

  const close = () => {
    setOpen(false)
    setMode('select')
    create.reset()
  }

  const getErrorMessage = () => {
    if (create.error === null)
      return ''
    if (create.error instanceof ApiError) {
      switch (create.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <>
      <Button icon={<CollectionsAdd20Regular />} onClick={() => setOpen(true)}>
        <T id="construction.ai-param.create.action" />
      </Button>
      {open && (
        <Dialog open onOpenChange={close} modalType="alert">
          <DialogSurface>
            {mode === 'select'
              ? <SelectMode setMode={setMode} />
              : mode === 'manual'
                ? (
                    <ConstructionAIParamsForm
                      constructionID={constructionID}
                      type="create"
                      defaultValues={DEFAULT_VALUES}
                      onSubmit={createAIParams}
                      isPending={create.isPending}
                      errorAlert={create.isError
                        ? (
                            <ErrorAlert
                              title={t('construction.ai-param.create.error')}
                              help={getErrorMessage()}
                              detail={parseServerError(create.error)}
                              onClose={() => create.reset()}
                            />
                          )
                        : undefined}
                    />
                  )
                : (
                    <ConstructionAIParamsCopy
                      orgID={orgID}
                      constructionID={constructionID}
                      close={close}
                      updateSelectedModel={updateSelectedModel}
                    />
                  )}
          </DialogSurface>
        </Dialog>
      )}
    </>
  )
}

function SelectMode(props: {
  setMode: (mode: CreateMode) => void
}): ReactElement {
  const { setMode } = props
  const styles = useStyles()
  return (
    <DialogBody>
      <DialogTitle
        action={(
          <DialogTrigger action="close">
            <Button
              appearance="subtle"
              aria-label="close"
              icon={<Dismiss24Regular />}
            />
          </DialogTrigger>
        )}
      >
        <T id="construction.ai-param.create.title" />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Button
          appearance="primary"
          onClick={() => setMode('copy')}
        >
          <T id="construction.ai-param.copy.action" />
        </Button>
        <div className={styles.center}>or</div>
        <Button onClick={() => setMode('manual')}>
          <T id="construction.ai-param.form.action" />
        </Button>
      </DialogContent>
    </DialogBody>
  )
}
