import { Button } from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import { Edit20Regular } from '@fluentui/react-icons'
import type { OrgAIModelParamsDetail } from '@aisekisan/anya-api'
import { ApiError, parseServerError, useOrgAIParamsUpdate } from '@aisekisan/anya-api'
import { OrgAIParamsForm } from './form/form'
import {
  type OrgAIParamsFormBody,
  parseAIModelParamsServer,
  toAIModelParamsServer,
} from './form/type'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

export function OrgAIParamsEdit(props: {
  aiModelParams: OrgAIModelParamsDetail
}): ReactElement {
  const { aiModelParams } = props
  const { orgID, id } = aiModelParams

  const t = useT()

  const [open, setOpen] = useState(false)

  const update = useOrgAIParamsUpdate({ orgID, id })
  const updateAIParams = (values: OrgAIParamsFormBody) => {
    const { params, displayName } = toAIModelParamsServer(values)
    update.mutate({ displayName, params }, {
      onSuccess: () => setOpen(false),
    })
  }

  const getErrorMessage = () => {
    if (update.error === null)
      return ''
    if (update.error instanceof ApiError) {
      switch (update.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <>
      <Button
        icon={<Edit20Regular />}
        onClick={() => setOpen(true)}
      >
        <T id="org.ai-param.update.action" />
      </Button>
      {open && (
        <OrgAIParamsForm
          type="update"
          defaultValues={parseAIModelParamsServer(aiModelParams)}
          onSubmit={updateAIParams}
          close={() => {
            setOpen(false)
            update.reset()
          }}
          isPending={update.isPending}
          errorAlert={update.isError
            ? (
                <ErrorAlert
                  title={t('org.ai-param.update.error')}
                  help={getErrorMessage()}
                  detail={parseServerError(update.error)}
                  onClose={() => update.reset()}
                />
              )
            : undefined}
        />
      )}
    </>
  )
}
