import { Body1Strong, Dropdown, Option, makeStyles, tokens, useIntl } from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import { useAIModelList, useOrgAIParamsList } from '@aisekisan/anya-api'
import { groupBy } from 'lodash'
import { OrgAIParamsCreate } from '../create'
import { OrgAIParamListRules } from './rules'
import { T } from '@/libs/intl/t'
import { EmptyContent } from '@/components/empty-content'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  tabList: {
    width: '100%',
    overflowX: 'auto',
  },
  aiModel: {
    display: 'flex',
    gap: tokens.spacingHorizontalS,
    alignItems: 'center',
  },
  container: {
    padding: `${tokens.spacingHorizontalS} 0`,
  },
})

export function OrgAIParamListAIModel(
  props: { orgID: string },
): ReactElement {
  const { orgID } = props

  const { lang } = useIntl()
  const t = useT()
  const styles = useStyles()

  const [value, setValue] = useState<string>()
  const [selectedModel, setSelectedModel] = useState<string>()

  const list = useOrgAIParamsList(orgID).data ?? []
  const models = useAIModelList().data ?? []

  if (list.length === 0)
    return <EmptyContent />

  const aiModels = Object.entries(groupBy(list, 'aiModelID'))

  return (
    <div className={styles.container}>
      <div className={styles.aiModel}>
        <Body1Strong>
          <T id="org.ai-param.list.ai-model" />
        </Body1Strong>
        <Dropdown
          placeholder={t('org.ai-param.ai-model.placeholder')}
          value={value ?? ''}
          selectedOptions={[selectedModel ?? '']}
          onOptionSelect={(_, data) => {
            setValue(data.optionText)
            setSelectedModel(data.optionValue)
          }}
        >
          {aiModels.map(([option]) => {
            const model = models.find((model) => {
              return model.ai_model_id === Number.parseInt(option)
                && model.locale === lang
            })
            return (
              <Option key={option} text={model?.localed ?? option} value={option}>
                {model?.localed}
              </Option>
            )
          })}
        </Dropdown>
        {selectedModel && <OrgAIParamsCreate orgID={orgID} aiModelID={selectedModel} />}
      </div>
      {aiModels.sort(([a], [b]) => a.localeCompare(b)).map(([aiModelID, rules]) => {
        if (aiModelID !== selectedModel)
          return null
        return (
          <OrgAIParamListRules
            key={aiModelID + rules.length} // Unmount component when add/delete rules
            aiModelRules={rules}
          />
        )
      })}
    </div>
  )
}
