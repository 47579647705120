import {
  Button,
  Dialog,
  DialogSurface,
} from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import { Edit20Regular } from '@fluentui/react-icons'
import type { ConstructionAIModelParamsDetail } from '@aisekisan/anya-api'
import {
  ApiError,
  parseServerError,
  useConstructionAIParamsUpdate,
} from '@aisekisan/anya-api'
import { ConstructionAIParamsForm } from './form/form'
import {
  type ConstructionAIParamsFormBody,
  parseAIModelParamsServer,
  toAIModelParamsServer,
} from './form/type'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

export function ConstructionAIParamsEdit(props: {
  aiModelParams: ConstructionAIModelParamsDetail
}): ReactElement {
  const { aiModelParams } = props
  const { constructionID, id } = aiModelParams

  const t = useT()

  const [open, setOpen] = useState(false)

  const update = useConstructionAIParamsUpdate({ constructionID, id })
  const updateAIParams = (values: ConstructionAIParamsFormBody) => {
    const { params } = toAIModelParamsServer(values)
    update.mutate({ params }, { onSuccess: () => setOpen(false) })
  }

  const close = () => {
    setOpen(false)
    update.reset()
  }

  const getErrorMessage = () => {
    if (update.error === null)
      return ''
    if (update.error instanceof ApiError) {
      switch (update.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <>
      <Button
        icon={<Edit20Regular />}
        onClick={() => setOpen(true)}
      >
        <T id="construction.ai-param.update.action" />
      </Button>
      {open && (
        <Dialog open onOpenChange={close} modalType="alert">
          <DialogSurface>
            <ConstructionAIParamsForm
              constructionID={constructionID}
              type="update"
              defaultValues={parseAIModelParamsServer(aiModelParams)}
              onSubmit={updateAIParams}
              isPending={update.isPending}
              errorAlert={update.isError
                ? (
                    <ErrorAlert
                      title={t('construction.ai-param.update.error')}
                      help={getErrorMessage()}
                      detail={parseServerError(update.error)}
                      onClose={() => update.reset()}
                    />
                  )
                : undefined}
            />
          </DialogSurface>
        </Dialog>
      )}
    </>
  )
}
