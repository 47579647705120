import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { PUBLIC_ROUTES } from './routes'
import { matchRoute } from './utils/route'
import { useLocation } from './hooks/use-location'
import { useAuth } from '@/hooks/use-auth'

const PUBLIC_PATHS = PUBLIC_ROUTES.map(route => route.path)

const APP_PATHS = [
  '/home',
  '/org/:id',
  '/org-preset/:id',
  '/org-preset-property/:id',
  '/org-preset-property/:orgPresetId/create',
  '/construction/:id',
  '/construction-property/:id',
  '/category/:id',
]

function AuthWrapper(props: { children: ReactNode }) {
  const auth = useAuth()
  const { children } = props

  const { location, navigate } = useLocation()

  const isAppPath = APP_PATHS.filter(path => matchRoute(location, path).isMatch)

  if (location === '/')
    void navigate('/home')

  useEffect(() => {
    if (!isAppPath)
      return
    switch (auth.status) {
      case 'guest':
        return void navigate('/sign-in', { replace: true })
      case 'user':
        return void navigate(location, { replace: true })
      case 'loading':
      default:
    }
  }, [auth.status, isAppPath])
  return children
}

export function StartPage(props: { children: ReactNode }) {
  const { children } = props
  const { location } = useLocation()

  const isPublicPath = PUBLIC_PATHS.includes(location)

  if (isPublicPath)
    return children

  return <AuthWrapper>{children}</AuthWrapper>
}
