import { Button, Subtitle2, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import { ChevronCircleDown20Regular, Copy20Regular, Delete20Regular } from '@fluentui/react-icons'
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import { useState } from 'react'
import type { EquipmentAttributeTreeType, EquipmentClass } from '../../type'
import type { EquipmentAttributeTreeNode, SelectedNode } from '../type'
import { cloneAndReplaceIDs, getLatestID, getSelectedLevel } from '../tree/utils'
import { PropertyTree } from '../tree/tree'
import { T } from '../../libs/intl/t'
import { DeleteChildProperty } from './delete'

const useStyles = makeStyles({
  children: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    flexWrap: 'wrap',
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    padding: tokens.spacingVerticalL,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  actionGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

interface Props {
  tree: EquipmentAttributeTreeNode
  selected: SelectedNode
  setSelected: Dispatch<SetStateAction<SelectedNode | null>>
  onChange: (tree: EquipmentAttributeTreeNode) => void
  root: EquipmentAttributeTreeNode
  activeIDNodes: number[]
  equip: EquipmentClass
  treeType: EquipmentAttributeTreeType
}

export function ChildrenProperty(props: Props): ReactElement {
  const { setSelected, tree, onChange, selected, root, activeIDNodes, equip, treeType } = props

  const styles = useStyles()

  const [deleteProperty, setDeleteProperty] = useState<boolean>(false)

  const onDuplicate = () => {
    const clonedNode = cloneAndReplaceIDs(tree, getLatestID(root) + 1)
    selected.node.children.push(clonedNode)
    onChange(selected.node)
  }

  const onRemove = () => {
    const removedID = selected.node.children.findIndex((node) => {
      return node.id === tree.id
    })
    selected.node.children.splice(removedID, 1)
    if (selected && selected.node.id !== tree.id) {
      const level = getSelectedLevel(root, selected.node.id, 0)
      setSelected({ node: selected.node, level })
    }
    onChange(selected.node)
  }

  return (
    <div className={styles.children}>
      <div className={styles.title}>
        <ChevronCircleDown20Regular />
        <Subtitle2>
          <T id="preset-property.children.label" />
        </Subtitle2>
      </div>
      <PropertyTree
        tree={tree}
        onChange={onChange}
        setSelected={setSelected}
        root={root}
        activeIDNodes={activeIDNodes}
        equip={equip}
        treeType={treeType}
      />
      <div className={styles.actionGroup}>
        <Button icon={<Copy20Regular />} onClick={onDuplicate}>
          <T id="preset-property.property.duplicate" />
        </Button>
        <Button icon={<Delete20Regular />} onClick={() => setDeleteProperty(true)}>
          <T id="preset-property.children.delete" />
        </Button>
      </div>
      {deleteProperty && <DeleteChildProperty onDelete={onRemove} close={() => setDeleteProperty(false)} />}
    </div>
  )
}
