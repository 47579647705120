import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
  Text,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import {
  ApiError,
  parseServerError,
  useOrgAIParamsCopyGlobalParams,
} from '@aisekisan/anya-api'
import { CollectionsAdd20Regular, Dismiss24Regular } from '@fluentui/react-icons'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  content: {
    width: '100%',
    maxHeight: '50vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
  actions: {
    marginTop: 'auto',
    alignSelf: 'flex-end',
  },
})

export function OrgAIParamsCopy(props: { orgID: string }): ReactElement {
  const { orgID } = props

  const t = useT()
  const styles = useStyles()

  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)

  const copyGlobalParams = useOrgAIParamsCopyGlobalParams(orgID)

  const copyParams = () => {
    copyGlobalParams.mutate(undefined, { onSuccess: close })
  }

  const getErrorMessage = () => {
    if (copyGlobalParams.error === null)
      return ''
    if (copyGlobalParams.error instanceof ApiError) {
      switch (copyGlobalParams.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogTrigger>
        <Button icon={<CollectionsAdd20Regular />}>
          <T id="org.ai-param.copy.title" />
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle
            action={(
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            )}
          >
            <T id="org.ai-param.copy.action" />
          </DialogTitle>
          <DialogContent className={styles.content}>
            <OverlaySpinner visible={copyGlobalParams.isPending} appearance="primary" />
            {copyGlobalParams.isError
              ? (
                  <div>
                    <ErrorAlert
                      title={t(`org.ai-param.create.error`)}
                      help={getErrorMessage()}
                      detail={parseServerError(copyGlobalParams.error)}
                      onClose={copyGlobalParams.reset}
                    />
                  </div>
                )
              : null}
            <Text>
              <T id="org.ai-param.copy.hint" />
            </Text>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <DialogTrigger disableButtonEnhancement>
              <Button>
                <T id="org.ai-param.form.cancel" />
              </Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={copyParams}>
              <T id="org.ai-param.create.submit" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
