import type { TableColumnDefinition } from '@aisekisan/bond'
import {
  Body1Strong,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  createTableColumn,
} from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { FireProtectionPipeDiameterFormula } from '@aisekisan/anya-api'
import { T } from '@/libs/intl/t'

export function OrgAIParamListFormula(
  props: { formulas: FireProtectionPipeDiameterFormula[] },
): ReactElement {
  const { formulas } = props

  const columns: TableColumnDefinition<FireProtectionPipeDiameterFormula>[] = [
    createTableColumn<FireProtectionPipeDiameterFormula>({
      columnId: 'num_head',
      compare: (a, b) => a.num_head_from - b.num_head_from,
      renderHeaderCell: () => <T id="org.ai-param.list.num-head" />,
      renderCell: (item) => {
        if (item.num_head_from === item.num_head_to)
          return item.num_head_from
        return (
          <T
            id="org.ai-param.list.range {{.from}} {{.to}}"
            params={{
              from: item.num_head_from.toString(),
              to: item.num_head_to.toString(),
            }}
          />
        )
      },
    }),
    createTableColumn<FireProtectionPipeDiameterFormula>({
      columnId: 'diameter',
      renderHeaderCell: () => <T id="org.ai-param.list.diameter" />,
      renderCell: item => item.diameter,
    }),
  ]

  return (
    <DataGrid
      items={formulas ?? []}
      columns={columns}
      sortable
      getRowId={row => Object.values(row).join('-')}
      focusMode="composite"
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>
              <Body1Strong>{renderHeaderCell()}</Body1Strong>
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<FireProtectionPipeDiameterFormula>>
        {({ item, rowId }) => (
          <DataGridRow<FireProtectionPipeDiameterFormula> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
}
