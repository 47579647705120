import { Field, Input, Subtitle2 } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { OrgAIParamsFormBody } from './type'
import { T } from '@/libs/intl/t'

export function OrgAIParamsFormDisplayName(props: {
  form: UseFormReturn<OrgAIParamsFormBody>
}): ReactElement {
  const { form } = props
  const { errors } = form.formState

  return (
    <Field
      label={(
        <Subtitle2>
          <T id="org.ai-param.display-name" />
        </Subtitle2>
      )}
      validationState={errors.displayName ? 'error' : 'none'}
      validationMessage={errors.displayName?.message}
    >
      <Input
        appearance="filled-darker"
        defaultValue={form.getValues('displayName')}
        {...form.register('displayName')}
      />
    </Field>
  )
}
