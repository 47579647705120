import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { groupBy } from 'lodash'
import {
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import type { OrgAIParamsFormBody } from './type'
import { OrgAIParamsFormHydrantRule } from './hydrant-rule'
import { OrgAIParamsFormRule } from './rule'
import { OrgAIParamsFormDisplayName } from './display-name'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  content: {
    maxHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
})

interface Props {
  type: 'create' | 'update'
  defaultValues: OrgAIParamsFormBody
  onSubmit: (values: OrgAIParamsFormBody) => void
  close: () => void
  isPending: boolean
  errorAlert?: ReactElement
}

export function OrgAIParamsForm(props: Props): ReactElement {
  const {
    type,
    defaultValues,
    onSubmit,
    close,
    isPending,
    errorAlert,
  } = props

  const t = useT()
  const styles = useStyles()

  const equipmentClasses = useEquipmentClasses().data ?? []

  const formulaSchema = z.object({
    id: z.string(),
    from: z.string()
      .min(1, { message: t('org.ai-param.required') })
      .refine(
        from => Number.parseInt(from) >= 0,
        { message: t('org.ai-param.invalid') },
      ),
    to: z.string()
      .min(1, { message: t('org.ai-param.required') })
      .refine(
        to => Number.parseInt(to) >= 0,
        { message: t('org.ai-param.invalid') },
      ),
    diameter: z.string().min(1, { message: t('org.ai-param.required') }),
  })

  const ruleSchema = z.object({
    id: z.string(),
    equipmentClass: z.string().min(1, t('org.ai-param.required')),
    formulas: z.array(formulaSchema.required()).superRefine((formulas, ctx) => {
      for (let i = 0; i < formulas.length; i++) {
        const from = Number.parseInt(formulas[i].from)
        const to = Number.parseInt(formulas[i].to)

        if (from > to) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('org.ai-param.invalid'),
            path: [`${i}.from`],
          })
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('org.ai-param.invalid'),
            path: [`${i}.to`],
          })
        }

        for (let j = i + 1; j < formulas.length; j++) {
          const a = formulas[i]
          const b = formulas[j]
          // Check if the ranges overlap
          if (Number.parseInt(a.from) <= Number.parseInt(b.to)
            && Number.parseInt(b.from) <= Number.parseInt(a.to)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('org.ai-param.overlap'),
              path: [`${j}.from`],
            })
          }
        }
      }
    }),
  })

  const schema = z.object({
    aiModelID: z.string(),
    hydrantRule: z.object({
      equipmentClass: z.string().min(1, t('org.ai-param.required')),
      exchangeValue: z.string()
        .min(1, t('org.ai-param.required'))
        .refine(
          exchangeValue => Number.parseInt(exchangeValue) >= 0,
          { message: t('org.ai-param.invalid') },
        ),
    }),
    rules: z.array(ruleSchema.required()).superRefine((rules, ctx) => {
      Object.values(groupBy(rules, 'equipmentClass'))
        .filter(item => item.length > 1)
        .forEach((items) => {
          items.forEach((rule) => {
            const index = rules.findIndex(r => r.id === rule.id)
            if (index === -1)
              return
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`${index}.equipmentClass`],
              message: t('org.ai-param.equipment-class-duplicate'),
            })
          })
        })
    }),
    displayName: z.string(),
  })

  const form = useForm<OrgAIParamsFormBody>({
    defaultValues,
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  })

  return (
    <Dialog open onOpenChange={close} modalType="alert">
      <DialogSurface>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <DialogTitle
              action={(
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              )}
            >
              <T id={`org.ai-param.${type}.title`} />
            </DialogTitle>
            <DialogContent className={styles.content}>
              {errorAlert && <div>{errorAlert}</div>}
              <OverlaySpinner visible={isPending} appearance="primary" />
              <OrgAIParamsFormDisplayName form={form} />
              <OrgAIParamsFormHydrantRule
                form={form}
                equipmentClasses={equipmentClasses}
              />
              <OrgAIParamsFormRule
                form={form}
                equipmentClasses={equipmentClasses}
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button>
                  <T id="org.ai-param.form.cancel" />
                </Button>
              </DialogTrigger>
              <Button appearance="primary" type="submit">
                <T id={`org.ai-param.${type}.submit`} />
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )
}
