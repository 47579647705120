import { Body1, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import { Collections20Regular } from '@fluentui/react-icons'
import { ConstructionAIParamsCreate } from '../create'
import { ConstructionAIParamListAIModel } from './ai-model'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  header: {
    marginBottom: 0,
  },
})

export function ConstructionAIParamList(props: {
  orgID: string
  constructionID: string
}): ReactElement {
  const { orgID, constructionID } = props

  const styles = useStyles()

  const [selectedModel, setSelectedModel] = useState<number>()

  return (
    <Panel
      title={<T id="construction.ai-param.list.title" />}
      icon={<Collections20Regular />}
      actions={(
        <ConstructionAIParamsCreate
          orgID={orgID}
          constructionID={constructionID}
          updateSelectedModel={aiModelID => setSelectedModel(aiModelID)}
        />
      )}
      headerClassName={styles.header}
    >
      <ConstructionAIParamListAIModel
        constructionID={constructionID}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <Body1 style={{ color: tokens.colorNeutralForeground3 }}>
        <T id="construction.ai-param.guideline" />
        <ul style={{ margin: 0 }}>
          <li>
            <T id="construction.ai-param.guideline.1" />
          </li>
          <li>
            <T id="construction.ai-param.guideline.2" />
          </li>
        </ul>
      </Body1>
    </Panel>
  )
}
