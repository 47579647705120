import { Field, Select, Subtitle2, useIntl } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useAIModelList } from '@aisekisan/anya-api'
import type { ConstructionAIParamsFormBody } from './type'
import { T } from '@/libs/intl/t'

export function ConstructionAIParamsFormAIModel(props: {
  form: UseFormReturn<ConstructionAIParamsFormBody>
  disabled?: boolean
}): ReactElement {
  const { form, disabled } = props
  const error = form.formState.errors.aiModelID

  const { lang } = useIntl()
  const aiModels = useAIModelList().data ?? []

  return (
    <Field
      label={(
        <Subtitle2>
          <T id="construction.ai-param.ai-model.field" />
        </Subtitle2>
      )}
      validationState={error ? 'error' : 'none'}
      validationMessage={error ? error.message : null}
    >
      <Select
        appearance="filled-darker"
        {...form.register('aiModelID')}
        disabled={disabled}
      >
        {aiModels.filter(model => model.locale === lang).map(model => (
          <option key={model.ai_model_id} value={model.ai_model_id}>
            {model.localed}
          </option>
        ))}
      </Select>
    </Field>
  )
}
