import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  OverlaySpinner,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import {
  useConstructionAIParamsList,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { z } from 'zod'
import { groupBy } from 'lodash'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dismiss24Regular } from '@fluentui/react-icons'
import type { ConstructionAIParamsFormBody } from './type'
import { ConstructionAIParamsFormAIModel } from './ai-model'
import { ConstructionAIParamsFormHydrantRule } from './hydrant-rule'
import { ConstructionAIParamsFormRule } from './rule'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  content: {
    maxHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
})

interface Props {
  constructionID: string
  type: 'create' | 'update'
  defaultValues: ConstructionAIParamsFormBody
  onSubmit: (values: ConstructionAIParamsFormBody) => void
  isPending: boolean
  errorAlert?: ReactElement
}

export function ConstructionAIParamsForm(props: Props): ReactElement {
  const {
    constructionID,
    type,
    defaultValues,
    onSubmit,
    isPending,
    errorAlert,
  } = props

  const t = useT()
  const styles = useStyles()

  const aiParams = useConstructionAIParamsList(constructionID).data ?? []
  const equipmentClasses = useEquipmentClasses().data ?? []

  const formulaSchema = z.object({
    id: z.string(),
    from: z.string()
      .min(1, { message: t('construction.ai-param.required') })
      .refine(
        from => Number.parseInt(from) >= 0,
        { message: t('construction.ai-param.invalid') },
      ),
    to: z.string()
      .min(1, { message: t('construction.ai-param.required') })
      .refine(
        to => Number.parseInt(to) >= 0,
        { message: t('construction.ai-param.invalid') },
      ),
    diameter: z.string().min(1, { message: t('construction.ai-param.required') }),
  })

  const ruleSchema = z.object({
    id: z.string(),
    equipmentClass: z.string().min(1, t('construction.ai-param.required')),
    formulas: z.array(formulaSchema.required()).superRefine((formulas, ctx) => {
      for (let i = 0; i < formulas.length; i++) {
        const from = Number.parseInt(formulas[i].from)
        const to = Number.parseInt(formulas[i].to)

        if (from > to) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('construction.ai-param.invalid'),
            path: [`${i}.from`],
          })
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('construction.ai-param.invalid'),
            path: [`${i}.to`],
          })
        }

        for (let j = i + 1; j < formulas.length; j++) {
          const a = formulas[i]
          const b = formulas[j]
          // Check if the ranges overlap
          if (Number.parseInt(a.from) <= Number.parseInt(b.to)
            && Number.parseInt(b.from) <= Number.parseInt(a.to)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('construction.ai-param.overlap'),
              path: [`${j}.from`],
            })
          }
        }
      }
    }),
  })

  const schema = z.object({
    aiModelID: z.string()
      .min(1, t('construction.ai-param.required'))
      .superRefine((aiModelID, ctx) => {
        if (type === 'update')
          return
        if (aiParams.some(item => item.aiModelID.toString() === aiModelID)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('construction.ai-param.ai-model-duplicate'),
          })
        }
      }),
    hydrantRule: z.object({
      equipmentClass: z.string().min(1, t('construction.ai-param.required')),
      exchangeValue: z.string()
        .min(1, t('construction.ai-param.required'))
        .refine(
          exchangeValue => Number.parseInt(exchangeValue) >= 0,
          { message: t('construction.ai-param.invalid') },
        ),
    }),
    rules: z.array(ruleSchema.required()).superRefine((rules, ctx) => {
      Object.values(groupBy(rules, 'equipmentClass'))
        .filter(item => item.length > 1)
        .forEach((items) => {
          items.forEach((rule) => {
            const index = rules.findIndex(r => r.id === rule.id)
            if (index === -1)
              return
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`${index}.equipmentClass`],
              message: t('construction.ai-param.equipment-class-duplicate'),
            })
          })
        })
    }),
  })

  const form = useForm<ConstructionAIParamsFormBody>({
    defaultValues,
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DialogBody>
        <DialogTitle
          action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
              />
            </DialogTrigger>
          )}
        >
          <T id={`construction.ai-param.${type}.title`} />
        </DialogTitle>
        <DialogContent className={styles.content}>
          {errorAlert && <div>{errorAlert}</div>}
          <OverlaySpinner visible={isPending} appearance="primary" />
          <ConstructionAIParamsFormAIModel
            form={form}
            disabled={type === 'update'}
          />
          <ConstructionAIParamsFormHydrantRule
            form={form}
            equipmentClasses={equipmentClasses}
          />
          <ConstructionAIParamsFormRule
            form={form}
            equipmentClasses={equipmentClasses}
          />
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button>
              <T id="construction.ai-param.form.cancel" />
            </Button>
          </DialogTrigger>
          <Button appearance="primary" type="submit">
            <T id={`construction.ai-param.${type}.submit`} />
          </Button>
        </DialogActions>
      </DialogBody>
    </form>
  )
}
