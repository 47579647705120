import { Button, Field, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Select, makeStyles, tokens } from '@aisekisan/bond'
import { Copy20Regular, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { EquipmentAttributeTreeNode } from '../type'
import { T } from '../../libs/intl/t'
import { useT } from '../../libs/intl/useT'
import type { CustomInsulOption } from './type'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  material2: {
    width: '254px',
  },
})

interface Props {
  insulTree: EquipmentAttributeTreeNode | null
  material1: string
  options: CustomInsulOption[]
  onUpdate: (options: CustomInsulOption[]) => void
}

export function InsulForm(props: Props): ReactElement {
  const { insulTree, material1, options, onUpdate } = props

  const t = useT()
  const styles = useStyles()

  const innerNode = insulTree
    ? findTreeNode(insulTree, Number(material1))?.children.at(0)
    : undefined

  const update = (value: Partial<CustomInsulOption>, index: number) => {
    options[index] = { ...options[index], ...value }
    onUpdate(options)
  }

  const remove = (index: number) => {
    options.splice(index, 1)
    onUpdate(options)
  }

  const duplicate = (option: CustomInsulOption) => {
    options.push(option)
    onUpdate(options)
  }

  return (
    <div className={styles.container}>
      {options.map((option, index) => {
        return (
          <div className={styles.item} key={index}>
            <Field label={t('preset-property.insul.inner')}>
              <Select
                appearance="filled-darker"
                className={styles.material2}
                value={option.material2}
                onChange={(event) => {
                  update({ material2: Number(event.target.value) }, index)
                }}
              >
                <option value={-1} disabled>
                  <T id="preset-property.action.select-one" />
                </option>
                {innerNode?.children?.map(({ id, value }) => (
                  <option key={id} value={id}>
                    {value}
                  </option>
                ))}
              </Select>
            </Field>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<MoreHorizontal20Regular />} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={<Delete20Regular />}
                    onClick={() => remove(index)}
                  >
                    <T id="preset-property.shape.delete" />
                  </MenuItem>
                  <MenuItem
                    icon={<Copy20Regular />}
                    onClick={() => duplicate(option)}
                  >
                    <T id="preset-property.shape.duplicate" />
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        )
      })}
    </div>
  )
}

type Node<T> = T & {
  id: string | number
  children: Node<T>[]
}

function findTreeNode<T>(
  node: Node<T>,
  id: string | number,
): Node<T> | null {
  if (node.id === id)
    return node

  for (const child of node.children) {
    const found = findTreeNode(child, id)
    if (found)
      return found
  }

  return null
}
