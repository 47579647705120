import { type ReactElement, useState } from 'react'
import { Button } from '@aisekisan/bond'
import { CollectionsAdd20Regular } from '@fluentui/react-icons'
import { ApiError, parseServerError, useOrgAIParamsCreate } from '@aisekisan/anya-api'
import { OrgAIParamsForm } from './form/form'
import { type OrgAIParamsFormBody, toAIModelParamsServer } from './form/type'
import { T } from '@/libs/intl/t'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

const DEFAULT_VALUES: OrgAIParamsFormBody = {
  aiModelID: '',
  hydrantRule: { equipmentClass: '', exchangeValue: '' },
  rules: [{
    id: crypto.randomUUID(),
    equipmentClass: '',
    formulas: [{
      id: crypto.randomUUID(),
      from: '',
      to: '',
      diameter: '',
    }],
  }],
  displayName: '',
}

export function OrgAIParamsCreate(props: {
  orgID: string
  aiModelID: string
}): ReactElement {
  const { orgID, aiModelID } = props

  const t = useT()

  const [open, setOpen] = useState(false)

  const create = useOrgAIParamsCreate(orgID)
  const createAIParams = (values: OrgAIParamsFormBody) => {
    const { aiModelID, params, displayName } = toAIModelParamsServer(values)
    create.mutate({ displayName, aiModelID, params }, {
      onSuccess: () => setOpen(false),
    })
  }

  const getErrorMessage = () => {
    if (create.error === null)
      return ''
    if (create.error instanceof ApiError) {
      switch (create.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <>
      <Button icon={<CollectionsAdd20Regular />} onClick={() => setOpen(true)}>
        <T id="org.ai-param.create.action" />
      </Button>
      {open && (
        <OrgAIParamsForm
          type="create"
          defaultValues={{ ...DEFAULT_VALUES, aiModelID }}
          onSubmit={createAIParams}
          close={() => {
            setOpen(false)
            create.reset()
          }}
          isPending={create.isPending}
          errorAlert={create.isError
            ? (
                <ErrorAlert
                  title={t('org.ai-param.create.error')}
                  help={getErrorMessage()}
                  detail={parseServerError(create.error)}
                  onClose={() => create.reset()}
                />
              )
            : undefined}
        />
      )}
    </>
  )
}
