import type { TabValue } from '@aisekisan/bond'
import {
  Divider,
  Tab,
  TabList,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import {
  type OrgAIModelParamsDetail,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { OrgAIParamListFormula } from './formula'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
  },
  divider: {
    flexGrow: 'initial',
  },
  table: {
    width: '100%',
  },
})

export function OrgAIParamListEquipment(props: {
  aiModelParams: OrgAIModelParamsDetail
  actions?: ReactNode
}): ReactElement {
  const { aiModelParams, actions } = props

  const { lang } = useIntl()
  const styles = useStyles()

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    aiModelParams.params.rules[0]?.equipment_class,
  )

  const equipmentClasses = useEquipmentClasses().data ?? []

  return (
    <div className={styles.container}>
      <TabList
        vertical
        selectedValue={selectedValue}
        onTabSelect={(_, data) => setSelectedValue(data.value)}
      >
        {aiModelParams.params.rules.map(({ equipment_class }) => {
          const equip = equipmentClasses.find(({ equipmentClass, locale }) => {
            return equipment_class === equipmentClass && locale === lang
          })
          return (
            <Tab key={equipment_class} value={equipment_class}>
              {equip?.localed}
            </Tab>
          )
        })}
        {actions}
      </TabList>
      <Divider vertical className={styles.divider} />
      <div className={styles.table}>
        {aiModelParams.params.rules.map((rule) => {
          if (rule.equipment_class !== selectedValue)
            return null
          return (
            <OrgAIParamListFormula
              formulas={rule.formulas}
              key={rule.equipment_class}
            />
          )
        })}
      </div>
    </div>
  )
}
