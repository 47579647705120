import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

function getListQueryKey(orgID: string) {
  return ['org-ai-param', 'list', orgID]
}

export function useOrgAIParamsList(orgID: string) {
  return useQuery({
    queryKey: getListQueryKey(orgID),
    queryFn: () => server.listOrgAiModelParams(orgID),
  })
}

export type OrgAIParamsCreateBody
  = Parameters<typeof server.createOrgAiModelParams>[1]

export function useOrgAIParamsCreate(orgID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (body: OrgAIParamsCreateBody) =>
      server.createOrgAiModelParams(orgID, body),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(orgID),
      })
    },
  })
}

export type OrgAIParamsUpdateBody
  = Parameters<typeof server.updateOrgAiModelParams>[1]

export function useOrgAIParamsUpdate(params: {
  id: number
  orgID: string
}) {
  const { id, orgID } = params
  const client = useQueryClient()
  return useMutation({
    mutationFn: (body: OrgAIParamsUpdateBody) =>
      server.updateOrgAiModelParams(id, body),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(orgID),
      })
    },
  })
}

export function useOrgAIParamsCopyGlobalParams(orgID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: () =>
      server.copyAiModelParamsToOrg(orgID),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(orgID),
      })
    },
  })
}

export function useOrgAIParamsDelete(orgID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (id: number) =>
      server.deleteOrgAiModelParams(id),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(orgID),
      })
    },
  })
}
